class Filters {
  constructor(settingsObj) {
    this.settings = settingsObj;
    this.curFilter = 'all';
    this.filterSet = this.getFilterSet();
    this.filterTarget = this.getFilterTarget();
    this.filterButtons = this.getFilterButtons();
    this.init();
  }

  getFilterSet() {
    return this.settings.filterSet || document.querySelector(`.Filters`);
  }

  getFilterTarget() {
    return this.settings.filterTarget || this.filterSet.nextElementSibling;
  }

  getFilterButtons() {
    return this.filterSet ? [...this.filterSet.querySelectorAll(`[data-filter]`)] : [];
  }

  init() {
    this.bindFilterButtonEvents();
    this.checkCurrentURL();
  }

  bindFilterButtonEvents() {
    this.filterButtons.forEach(el => {
      el.addEventListener(`click`, this.filterElements.bind(this));
    });
  }

  checkCurrentURL() {
    this.filterButtons.forEach(el => {
      if (window.location.hash === `#${el.dataset.filter}`) {
        el.click();
      }
    });
  }

  filterElements(event) {
    this.curFilter = event.target.dataset.filter;
    this.updateFilteredElements();
    this.updateFilterButtonStates(event.target);
  }

  updateFilteredElements() {
    this.filterTarget.querySelectorAll(`[data-filter]`).forEach(el => {
      if (el.dataset.filter === this.curFilter || this.curFilter === 'all') {
        el.classList.remove(`hidden`);
      } else {
        el.classList.add(`hidden`);
      }
    });
  }

  updateFilterButtonStates(activeButton) {
    this.filterButtons.forEach(el => {
      if (el === activeButton) {
        el.setAttribute('aria-pressed', 'true');
      } else {
        el.removeAttribute('aria-pressed');
      }
    });
  }
}

export default Filters;
