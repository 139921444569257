class Video {
  constructor(settings) {
    this.settings = settings;
    this.videoPlayer = settings.videoPlayer;
    this.videoControls = settings.videoControls || this.videoPlayer.parentNode.querySelector('.Video__controls');
    this.videoToggle = settings.videoToggle || this.videoPlayer.parentNode.querySelector('.Video__button');
    this.playing = settings.playing || this.videoPlayer.dataset.playing || '0';

    if (!this.videoPlayer || !this.videoControls || !this.videoToggle) {
      return;
    }

    this.init();
  }

  init() {
    this.updatePlaybackState();

    this.videoPlayer.addEventListener('mousemove', () => {
      // this.buttonFadeIn()
    });

    this.videoToggle.addEventListener('click', () => {
      this.togglePlay();
    });

    this.videoToggle.addEventListener('focus', () => {
      // this.buttonFadeIn()
    });
  }

  togglePlay() {
    this.playing = this.playing === '1' ? '0' : '1';
    this.updatePlaybackState();
  }

  updatePlaybackState() {
    if (this.playing === '1') {
      this.videoPlayer.play();
      this.videoToggle.textContent = 'Pause';
    } else {
      this.videoPlayer.pause();
      this.videoToggle.textContent = 'Play';
    }
    this.videoPlayer.dataset.playing = this.playing;
  }

  buttonFadeOut() {
    this.videoControls.animate({
      opacity: 0,
    }, {
      duration: 200,
      easing: 'ease-out',
      fill: 'forwards',
      iteration: 1,
    });
  }

  buttonFadeIn() {
    this.videoControls.animate({
      opacity: 1,
    }, {
      duration: 200,
      easing: 'ease-out',
      fill: 'forwards',
      iteration: 1,
    });
  }
}

export default Video;
