class Dialog {
  /**
   * Initializes a new instance of the Dialog class.
   * @param {Object} settingsObj - An object containing the settings for the dialog.
   * @param {HTMLElement} settingsObj.button - The dialog button element.
   * @param {HTMLElement} settingsObj.target - The dialog target element.
   * @param {HTMLElement} settingsObj.close - The dialog close element.
   */
  constructor(settingsObj) {
    this.settings = settingsObj;

    this.dialogButton = this.settings.button || document.querySelector('[data-dialog]');
    if (!this.dialogButton) {
      console.warn('No Dialog Button');
      return;
    }

    this.dialogTarget = this.settings.target || document.querySelector(`#${this.dialogButton.dataset.modal}`);
    if (!this.dialogTarget) {
      console.warn('No Dialog Target');
      return;
    }

    this.dialogClose = this.settings.close || this.dialogTarget.querySelector('[class*="close"], [class*="Close"], [aria-label*="close"]');
    if (!this.dialogClose) {
      console.warn('No Dialog Close');
      return;
    }

    this.curState = false;

    this.init();
  }

  init() {
    this.dialogButton.addEventListener('click', this.toggleDialogVisibility.bind(this));
    this.dialogButton.addEventListener('keypress', (e) => {
      if ([' ', 'Spacebar', 'Enter'].includes(e.key) || [32, 13].includes(e.keyCode)) {
        this.toggleDialogVisibility(e);
      }
    });
    this.dialogClose.addEventListener('click', this.toggleDialogVisibility.bind(this));
  }

  toggleDialogVisibility(event) {
    // this.preventDefault();
    if (event && event.currentTarget.tagName.toLowerCase() === 'a') {
      event.preventDefault();
    }

    this.curState = !this.curState;

    this.dialogButton.setAttribute('aria-expanded', this.curState);
    this.dialogTarget.classList.toggle('Dialog--open');

    if (this.curState) {
      this.openDialog();
    } else {
      this.closeDialog();
    }
  }

  openDialog() {
    this.focusableElements = Array.from(this.dialogTarget.querySelectorAll('iframe, [tabindex="0"], a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'));
    this.firstFocusableEl = this.focusableElements[0];
    this.lastFocusableEl = this.focusableElements[this.focusableElements.length - 1];

    this.dialogTarget.setAttribute('tabindex', -1);
    this.dialogTarget.focus();
    this.dialogTarget.showModal();
    this.dialogTarget.setAttribute('open', '');

    document.addEventListener('keydown', this.keypressHandler.bind(this));
    document.body.classList.add('no-scroll');

    if(this.dialogTarget.querySelector(`video`)){
      this.dialogTarget.querySelector(`video`).play();
    }
  }

  closeDialog() {
    const iframe = this.dialogTarget.querySelector('iframe');
    if (iframe) {
      const src = iframe.src;
      iframe.src = '';
      iframe.src = src;
    }

    this.dialogTarget.setAttribute('tabindex', 0);
    this.dialogTarget.close();
    this.dialogTarget.removeAttribute('open');

    this.dialogButton.focus();
    document.body.classList.remove('no-scroll');

    Array.from(this.dialogTarget.querySelectorAll(`video`)).map(el=>{
      el.pause()
      el.currentTime = 0;
    })
  }

  keypressHandler(event) {
    const isEscape = event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27;
    const isTab = event.key === 'Tab' || event.keyCode === 9;

    if (!isEscape && !isTab) {return;}

    if (isEscape && this.curState) {
      this.toggleDialogVisibility();
      this.dialogButton.focus();
    }

    if (isTab) {
      if (event.shiftKey && document.activeElement === this.firstFocusableEl) {
        event.preventDefault();
        this.lastFocusableEl.focus();
      } else if (!event.shiftKey && document.activeElement === this.lastFocusableEl) {
        event.preventDefault();
        this.firstFocusableEl.focus();
      }
    }
  }
}

export default Dialog;
