export default function Search() {
  // Cache DOM elements
  const searchBar = document.querySelector(`.Search`);
  const searchButton = document.querySelector(`.Nav__menu-search`);
  const searchInput = searchBar?.querySelector(`input`);
  const searchCloseButton = document.querySelector(`.Search__close`); // Add this line

  let isSearchActive = false;

  /**
   * Handles keyboard events for accessibility
   * @param {KeyboardEvent} e - The keyboard event
   */
  function keyHandler(e) {
    if (e.key === 'Escape' && isSearchActive) {
      toggleSearchBar();
    }
  }

  /**
   * Toggles the search bar visibility and accessibility
   */
  function toggleSearchBar() {
    isSearchActive = !isSearchActive;

    // Toggle classes for visual state
    searchBar.toggleAttribute(`open`);

    // Update ARIA attributes for accessibility
    searchButton.setAttribute(`aria-expanded`, isSearchActive);
    searchBar.setAttribute(`aria-hidden`, !isSearchActive);

    if (isSearchActive) {
      searchInput?.focus();
      document.addEventListener('keydown', keyHandler);
    } else {
      searchButton.focus();
      document.removeEventListener('keydown', keyHandler);
    }
  }

  // Initialize event listeners if elements exist
  if (searchButton) {
    searchButton.addEventListener(`click`, toggleSearchBar);
  }

  if (searchCloseButton) {
    searchCloseButton.addEventListener(`click`, () => {
      if (isSearchActive) {
        toggleSearchBar();
      }
    });
  }
}

// EOF
