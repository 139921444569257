export default function Nav() {
  const nav = document.querySelector(`.Nav`);
  const header = document.querySelector(`.Header`);
  const button = document.querySelector(`.Nav__toggle`);
  const subMenuToggles = document.querySelectorAll('.Nav__menu-toggle');
  let curState = false;

  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  function keyHandler(e) {
    const focusableEls = Array.from(header.querySelectorAll(`iframe, iframe *, [tabindex="0"], a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])`));
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    let isEscape = (e.key === `Escape` || e.key === `Esc` || e.keyCode === 27);

    if (e.key === 'Tab' || e.keyCode === 9) {
      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    }

    if (isEscape && curState) {
      toggleNav();
    }
  }

  function checkScreenSize() {
    if (window.innerWidth > 1100) {
      closeNav();
      closeAllSubMenus();
    }
  }

  function closeAllSubMenus() {
    subMenuToggles.forEach(toggle => {
      const parentItem = toggle.closest('.Nav__menu-item');
      toggle.setAttribute('aria-expanded', 'false');
      parentItem.removeAttribute('open');
    });
  }

  const toggleNav = function () {
    document.querySelector(`body`).classList.toggle(`no-scroll`);
    curState = !curState
    if (curState) {
      nav.classList.toggle(`Nav--isactive`);
      document.addEventListener(`keydown`, keyHandler);

      setTimeout(() => {
        nav.classList.toggle(`Nav--visible`);
      }, 100);
    } else {
      button.focus();

      nav.classList.toggle(`Nav--visible`);
      document.removeEventListener(`keydown`, keyHandler);

      setTimeout(() => {
        nav.classList.toggle(`Nav--isactive`);
      }, 200);
    }
    button.setAttribute(`aria-expanded`, curState);
  };

  const closeNav = function() {
    curState = false;
    button.focus();
    nav.classList.remove(`Nav--visible`, `Nav--isactive`);
    document.removeEventListener(`keydown`, keyHandler);
    document.querySelector(`body`).classList.remove(`no-scroll`);
    button.setAttribute(`aria-expanded`, curState);
  }

  const toggleSubMenu = function(e) {
    const toggle = e.currentTarget; // Use currentTarget instead of target
    const parentItem = toggle.closest('.Nav__menu-item');
    const subMenu = parentItem.querySelector('.Nav__menu-sub');
    const isExpanded = toggle.getAttribute('aria-expanded') === 'true';

    toggle.setAttribute('aria-expanded', !isExpanded);
    parentItem.toggleAttribute('open');
  }

  if (button) {
    button.addEventListener(`click`, toggleNav);
  }

  subMenuToggles.forEach(toggle => {
    toggle.addEventListener('click', toggleSubMenu);
  });

  const navItems = nav.querySelectorAll(`a`);
  navItems.forEach(item => item.addEventListener(`click`, closeNav));

  // Add event listener for window resize
  window.addEventListener('resize', checkScreenSize);

  // Initial check on load
  checkScreenSize();
}

// EOF
